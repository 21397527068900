import { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { getAllGifts } from "./libs/services/gifts";

import Element from "./components/element";
import Header from "./components/header";
import Timer from "./components/timer";
import Card from "./components/card";
import FilterTag from "./components/filter-tag";
import CopiableInput from "./components/copiable-input";
import Drawer from "./components/drawer";
import Snackbar from "./components/snackbar";
import { DrawerProvider } from "./contexts/drawer-context";

import { ReactComponent as CheckIcon } from "./libs/assets/icons/check-snackbar-icon.svg";
import { ReactComponent as HeroLogo } from "./libs/assets/logo/hero-logo.svg";
import LeavesCenter from "./libs/assets/images/elements/new-center-leaves.png";
import LeavesOutContainerRight from "./libs/assets/images/elements/new-leaves-out-container-right.png";
import LeavesOutContainerLeft from "./libs/assets/images/elements/new-leaves-out-container-left.png";
import IateClubImage from "./libs/assets/images/iate-club-image.jpg";
import DressCode from "./libs/assets/images/dresscode-image.png";

import styles from "./App.module.css";
import Input from "./components/input";
import Button from "./components/button";
import AmaountBlock from "./components/amount-block";
import { confirmGuest, FormatedGuestData } from "./libs/services/guests";
import useDeviceDetect from "./hooks/useDeviceDetect";

const GUEST_INITIAL_STATE = {
  name: "",
  adults_number: 1,
  kids_number: 0,
  message: "",
  phone: "",
};

function App() {
  const { isMobile } = useDeviceDetect();
  const [tags, setTags] = useState({
    higher_value: false,
    lower_value: true,
    all: true,
    honey_moon: false,
    eletronic: false,
    others: false,
  });

  const [showSnackBar, setShowSnackBar] = useState(false);

  const [guestData, setGuestData] = useState<GuestType>(GUEST_INITIAL_STATE);

  const guestMutation = useMutation({
    mutationFn: (newData: FormatedGuestData) => confirmGuest(newData),
  });

  const [choosedGifts, setChoosedGifts] = useState<GiftType[]>([]);

  const { data: giftList, refetch } = useQuery({
    queryKey: ["gifts", tags],
    queryFn: () => {
      // eslint-disable-next-line array-callback-return
      const filters = Object.keys(tags).filter((tag) => {
        //@ts-ignore
        if (tag !== "higher_value" && tag !== "lower_value") return tags[tag];
      });
      const computer = tags.higher_value ? "higher_value" : "lower_value";

      return getAllGifts(filters, computer);
    },
  });

  const onSelectTag = (name: TagsKeys) => {
    const filters = ["all", "honey_moon", "eletronic", "others"];
    const computers = ["higher_value", "lower_value"];

    /* On computers you can choose only one of the options */
    if (computers.includes(name)) {
      computers.forEach((comp) => {
        if (!tags[name] && name === comp)
          setTags((prev) => ({ ...prev, [name]: true }));
        else setTags((prev) => ({ ...prev, [comp]: false }));
      });
    } else if (filters.includes(name)) {
      filters.forEach((filter) => {
        if (filter === name) setTags((prev) => ({ ...prev, [filter]: true }));
        else setTags((prev) => ({ ...prev, [filter]: false }));
      });
    }

    if (name === "eletronic" || name === "others" || name === "honey_moon") {
      const verifyAll = {
        others: tags.others,
        honey_moon: tags.honey_moon,
        eletronic: tags.eletronic,
        [name]: true,
      };

      if (Object.keys(verifyAll).filter((key) => verifyAll[key]).length > 2)
        setTags((prev) => ({
          ...prev,
          eletronic: false,
          all: true,
          others: false,
          honey_moon: false,
        }));
    }
  };

  const handleChooseGift = (id: number) => {
    const item = giftList.find((gift: GiftType) => gift.id === id);
    if (!choosedGifts.find((gift: GiftType) => gift.id === id))
      setChoosedGifts((prev) => [...prev, item]);
  };

  const onChangeInput = (name: string, value: string) => {
    setGuestData((prev) => ({ ...prev, [name]: value }));
  };

  const validateGuestForm = useMemo(() => {
    let error = { name: "", email: "", adults_number: "" };
    const MANDATORY_FIELDS = ["name", "phone"];

    Object.keys(guestData).forEach((key) => {
      if (
        //@ts-ignore
        !guestData[key] &&
        MANDATORY_FIELDS.includes(key)
      )
        error = { ...error, [key]: "Esse campo é obrigatório" };
    });

    return error;
  }, [guestData]);

  const onConfirmPresenceSuccess = () => {
    setShowSnackBar(true);
    setGuestData(GUEST_INITIAL_STATE);
  };

  const onConfirmPresenceFailure = () => {
    console.log("error", guestMutation.error);
  };

  const onConfirmPresence = () => {
    const error = validateGuestForm;
    setGuestData((prev) => ({ ...prev, error }));
    const canSubmit =
      Object.keys(error).filter(
        //@ts-ignore
        (key) => error[key]
      ).length < 1;

    if (canSubmit) {
      guestMutation.mutate(guestData, {
        onSuccess: onConfirmPresenceSuccess,
        onError: onConfirmPresenceFailure,
      });
    }
  };

  return (
    <main>
      {isMobile && <Header />}
      <section className={styles.home} id="home">
        <div className={styles.overlay}></div>
        <HeroLogo />
      </section>
      {!isMobile && <Header />}
      <section className={styles.countDown}>
        <div>
          <Element
            src={LeavesCenter}
            size={
              isMobile ? { width: 104, height: 29 } : { width: 272, height: 77 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <h2>Contagem Regressiva</h2>
          <Timer date={{ day: "09", month: "11", year: "2024" }} />
        </div>
      </section>
      <section className={styles.note}>
        <div>
          <Element
            src={LeavesOutContainerRight}
            size={
              isMobile
                ? { width: 64, height: 100 }
                : { width: 120, height: 160 }
            }
            position={{ top: -13, right: 0 }}
          />
          <h2>Queridos amigos e familiares, sejam bem vindos ao nosso site!</h2>
          <p>
            Aqui iremos compartilhar informações sobre o
            <b>
              {" "}
              local do casamento, lista de presentes e sugestões de trajes.
            </b>
          </p>
          <p>
            Ah, <b> é importante também confirmar sua presença.</b> É só clicar
            no menu "Confirme sua Presença" e preencher os dados necessários.
          </p>
          <p>Aguardamos vocês no nosso grande dia!</p>
          <Element
            src={LeavesOutContainerLeft}
            size={
              isMobile
                ? { width: 64, height: 100 }
                : { width: 120, height: 160 }
            }
            position={{ bottom: 0, left: 0 }}
          />
        </div>
      </section>
      <section className={styles.ceremony} id="ceremony">
        <div>
          <Element
            src={LeavesCenter}
            size={
              isMobile ? { width: 136, height: 38 } : { width: 272, height: 77 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <h2>Cerimônia</h2>
          <img
            src={IateClubImage}
            alt="Local da cerimonia: Iate Clube do Natal"
          />
          <div className={styles.ceremonyContent}>
            <p>
              Gostaríamos muito de contar com a presença de todos vocês no
              momento em que nossa união será abençoada diante de Deus!
            </p>

            <p>
              Contamos com vocês dia <br />
              <b>09 de Novembro de 2024, às 15h30 </b>
              <br />
              no <b>Iate Clube do Natal</b>
              <br />
            </p>

            <address>
              Endereço: R. Cel. Flamínio - Santos Reis, Natal - RN, 59010-500.
            </address>

            <Link
              to={
                "https://www.google.com/maps/place/Iate+Clube+do+Natal/@-5.7656462,-35.2037438,17z/data=!3m1!4b1!4m6!3m5!1s0x7b3008f16597ab7:0x53e4d5ef9e433b4b!8m2!3d-5.7656462!4d-35.2037438!16s%2Fg%2F11b6htbxqt?entry=ttu"
              }
              target="_blank"
            >
              Clique aqui para ver a localização no Google Maps
            </Link>
          </div>
        </div>
      </section>
      <section className={styles.dressCode} id="dressCode">
        <div>
          <Element
            src={LeavesCenter}
            size={
              isMobile ? { width: 136, height: 38 } : { width: 272, height: 77 }
            }
            position={{ top: 80, horizontalCenter: true }}
          />
          <h2>Trajes para convidados</h2>
          <div className={styles.dresscodeContent}>
            <p>
              O traje social completo é o escolhido para o dia 09 de Novembro.
            </p>
            <p>
              Às mulheres recomendamos vestidos longos
              <b> evitando preto e verde</b> (mas lembrem-se também:
              <b>
                {" "}
                branco ou tons muito claros como off-white, é cor exclusiva da
                noiva).
              </b>
            </p>
            <p>
              Aos homens, recomendamos traje esporte fino,
              <b> evitando ir com terno na cor de linho ou cinza grafite</b>
              (camisas brancas/off-white/cinzas estão liberadas).
            </p>
            <p>Dá uma olhada nas imagens abaixo como inspiração:</p>
          </div>
          <img src={DressCode} alt="Dress code do casamento" />
        </div>
      </section>
      <section id="giftList" className={styles.giftList}>
        <div>
          <Element
            src={LeavesCenter}
            size={
              isMobile ? { width: 136, height: 38 } : { width: 272, height: 77 }
            }
            position={{ horizontalCenter: true }}
          />
          <div className={styles.pixContainer}>
            <h2>Lista de presentes</h2>
            <p>
              Selecione um presente da lista ou mande um PIX com valor
              personalizado para a noiva utilizando o Código Pix abaixo:
            </p>
            <CopiableInput
              content="mariaemiguel09.11@gmail.com"
              placeholder="Copie o código e utilize o PIX Copia e Cola no aplicativo do seu banco"
            />
          </div>
          <div className={styles.giftsContainer}>
            <div className={styles.priceFilters}>
              <h3>Ordenar por:</h3>
              <FilterTag
                name="higher_value"
                label="Maior preço"
                onSelectTag={onSelectTag}
                checked={tags.higher_value}
              />
              <FilterTag
                name="lower_value"
                label="Menor preço"
                onSelectTag={onSelectTag}
                checked={tags.lower_value}
              />
            </div>
            <div className={styles.categoriesFilters}>
              <h3>Filtrar por:</h3>
              <FilterTag
                name="all"
                label="Todos"
                onSelectTag={onSelectTag}
                checked={tags.all}
              />
              <FilterTag
                name="honey_moon"
                label="Lua de mel"
                onSelectTag={onSelectTag}
                checked={tags.honey_moon}
              />
              <FilterTag
                name="eletronic"
                label="Eletrodomésticos"
                onSelectTag={onSelectTag}
                checked={tags.eletronic}
              />
              <FilterTag
                name="others"
                label="Outros"
                onSelectTag={onSelectTag}
                checked={tags.others}
              />
            </div>
            <ul className={styles.giftList}>
              {giftList?.map(
                ({ id, name, cost, photo_src, was_gifted }: GiftType) => (
                  <li key={id + cost}>
                    <Card
                      description={name}
                      price={cost}
                      src={photo_src}
                      alt={name}
                      disabled={!!was_gifted}
                      giftId={id}
                      handleChooseGift={handleChooseGift}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
          <DrawerProvider
            giftList={choosedGifts}
            setGiftList={setChoosedGifts}
            refetchList={() => {
              refetch();
            }}
          >
            <Drawer />
          </DrawerProvider>
        </div>
      </section>
      <section id="confirm" className={styles.confirm}>
        <div>
          <Element
            src={LeavesCenter}
            size={
              isMobile ? { width: 136, height: 38 } : { width: 272, height: 77 }
            }
            position={{ horizontalCenter: true }}
          />
          <div className={styles.confirmContainer}>
            <h2>Confirme sua presenca</h2>
          </div>
          <div className={styles.confirmForm}>
            <Input
              label="Nome completo"
              name="name"
              onChange={onChangeInput}
              value={guestData.name}
              error={guestData.error?.name}
            />
            <AmaountBlock
              name="adults_number"
              label="Quantidade de adultos"
              placeholder="13 anos ou mais"
              onChange={onChangeInput}
              initialValue={1}
              value={guestData.adults_number}
            />
            <AmaountBlock
              name="kids_number"
              label="Quantidade de crianças"
              placeholder="0 - 12 anos"
              onChange={onChangeInput}
              value={guestData.kids_number}
            />
            <Input
              label="Telefone"
              name="phone"
              onChange={onChangeInput}
              value={guestData.phone}
              error={guestData.error?.phone}
              placeholder="Vocês receberão a confirmação da presença neste numero"
              mask="(99) 9 9999-9999"
            />
            <Input
              label="Quer deixar um comentário para os noivos? (Opcional)"
              name="message"
              onChange={onChangeInput}
              value={guestData.message}
            />
            <Button label="Confirmar presença" onClick={onConfirmPresence} />
          </div>
        </div>
        {showSnackBar && (
          <Snackbar
            onClose={() => {
              setShowSnackBar(false);
            }}
            Icon={CheckIcon}
            label="Sua presença foi confirmada"
          />
        )}
      </section>
    </main>
  );
}

export default App;
